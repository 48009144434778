import dayjs from 'dayjs';

// Check if accessToFind object exist in accesses state
export const accessAlreadyExist = (accessToFind, accesses) =>
  accesses.find((access) => access?.baseAccess === accessToFind?.baseAccess);

// Return accesses state array without accessToRemove object
export const accessesUpdated = (accessToRemove, accesses) =>
  accesses.filter(
    (access) => access?.baseAccess !== accessToRemove?.baseAccess
  );

export const formatDate = (date) =>
  date ? dayjs(date).format('YYYY-MM-DD') : null;
// Return app environment worded to auth API
export function getClientEnvironment() {
  if (process.env.REACT_APP_ENV === 'stage') {
    return 'prod';
  }

  return 'prod';
}

// ----------------------
// - IRI TOOLS
// ----------------------
// Return user object field
export const getUsername = (userObj) => userObj?.username;
export const getFirstName = (userObj) => userObj?.firstName;
export const getLastName = (userObj) => userObj?.lastName;
export const getEmail = (userObj) => userObj?.email;
export const getExpiresAt = (userObj) => userObj?.expiresAt || null;
export const getAccesses = (userObj) => userObj?.accesses;
export const getPassword = (userObj) => userObj?.password;
export const getUUID = (userObj) => userObj?.uuid;

// Return user profile object field
export const getCompanyName = (userProfileObj) => userProfileObj?.companyName
export const getProductName = (userProfileObj) => userProfileObj?.productName

// Return formated IRI
export const formatUserIRI = (uuid) => `/users/${uuid}`;

// Getters for API response
export const getHydraMember = (data) => data['hydra:member']

// ----------------------
// - USER TOOLS
// ----------------------
export const createUserObject = (user) => {
  const userObj = {
    username: getUsername(user),
    email: getEmail(user),
    firstName: getFirstName(user),
    lastName: getLastName(user),
    expiresAt: getExpiresAt(user),
    accesses: getAccesses(user),
  };

  if (getPassword(user)) {
    userObj.password = getPassword(user);
  }

  return userObj;
};

// ----------------------
// - PROJECT TOOLS
// ----------------------
/**
 * Create array for LogoForm.jsx to render Tabs, Tab title and endpoint.
 * Tab title is created from project api endpoint url (removing https:// and .skwr.io)
 * @param {Array} envs Project API endpoints
 * @returns {Array} envTabs
 */
export function createProjectApiEnvTabs(envs) {
  if (!envs) {
    console.error('Any params passed to createProjectApiEnvTabs()');
    return [];
  }
  const envTabs = [];

  for (let i = 0; i < envs.length; i++) {
    envTabs.push({
      ENDPOINT: envs[i],
      title: envs[i].replace('https://', '').replace('.skwr.io', ''),
    });
  }

  return envTabs;
}

// Create a FormData to upload to S3
export function createAssetFormData(fields, file) {
  const formData = new FormData();
  fields.key && formData.append('key', fields.key);
  fields.acl && formData.append('acl', fields.acl);
  fields.Policy && formData.append('Policy', fields.Policy);
  fields['X-Amz-Credential'] &&
    formData.append('X-Amz-Credential', fields['X-Amz-Credential']);
  fields['X-Amz-Algorithm'] &&
    formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
  fields['X-Amz-Date'] && formData.append('X-Amz-Date', fields['X-Amz-Date']);
  fields['X-Amz-Signature'] &&
    formData.append('X-Amz-Signature', fields['X-Amz-Signature']);
  file && formData.append('file', file);

  return formData;
}
