import { getClientEnvironment } from '../tools/helpers';

export const ROUTES = {
  HOME: '/home',
  LOGIN: '/login',
  NOT_FOUND: '*',
  ROOT: '/',
};

export const APP_NAME = 'User Manager';
export const APP_INFO = {
  client: 'clients/user_manager',
  environment: `environments/${getClientEnvironment()}`,
};

export const API = {
  AUTH_ENDPOINT: process.env.REACT_APP_API_AUTH,
  PROJECT: {
    ENDPOINTS: [
      process.env.REACT_APP_API_PROJECT_DEV,
      process.env.REACT_APP_API_PROJECT_PROD,
    ],
    USER_PROFILE: 'users',
    USER_LOGO: {
      ROUTE: 'user_logos',
      USER_UUID_PARAMS: 'user.uuid',
    },
  },
  SECURITY_LOGIN: 'security/login',
  USERS: 'users',
  BASE_ACCESSES: 'base_accesses',
  TOKEN_DURATION: 300000,
};

export const LOCAL_STORAGE = {
  USER_KEY: 'user',
};

export const USER_LOGO_FILE_TYPE = 'image/png, image/jpeg';
export const UPLOAD_STATUS = {
  START: '/cloud_resource_upload_statuses/upload_started',
  SUCCESS: '/cloud_resource_upload_statuses/success',
  ERROR: '/cloud_resource_upload_statuses/failed',
  PENDING: '/cloud_resource_upload_statuses/pending',
  NOT_FOUND: '/cloud_resource_upload_statuses/not_found',
  NOT_VERIFIED: '/cloud_resource_upload_statuses/not_verified',
};
export const S3_STATUS_CODE = {
  NO_CONTENT: 204,
};

export const USER_PROFILE_ASSET_TYPE = {
  EDITOR_BIG: 'editor_big',
  EDITOR_SMALL: 'editor_small',
};
